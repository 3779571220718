.task-list {
    margin-top: 54px;
}

.task-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: 1px solid #eaeaea;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease-in-out;
}
.task-item:hover {
    background-color: #f1f1f1;
}
.symbol {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}
.duedate {
    flex: 1;
    text-align: center;
    color: #666;
}
.taskname {
    flex: 2;
    font-weight: bold;
    display: flex;
    justify-content: center;
}
.taskname2 {
    flex: 2;
    margin-left: 10px;
}
.task-item button {
    padding: 10px 10px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    flex: 1;
    max-height: 50px;
}
.task-item button:hover {
    background-color: #ff3333;
}
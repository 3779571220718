.modall {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}

.modall.show {
    opacity: 1;
    pointer-events: visible;
}
.modall.enter-done {
    opacity: 1;
    pointer-events: visible;
}
.modall.exit {
    opacity: 0;
}
.modall-content {
    width: 500px;
    background-color: #fff;
    transform: translateY(-200px);
    transition: all 0.3s ease-in-out;
}
.modall.enter-done .modall-content {
    transform: translateY(0);
}
.modall.exit .modall-content {
    transform: translateY(-200px);
}
.modall.show .modall.content {
    transform: translateY(0);
}
.modall-header, .modall-footer {
    padding: 10px;
}

.modall-title {
    margin: 0;
}

.modall-body {
    padding: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.modall-footer .button {
    width: 30%;
    padding: 12px;
    margin-top: 20px;
    background: #28a745; /* Green background */
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}
.modall-footer .button:hover {
    background: #218838; /* Darker green on hover */
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 390px;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%);
    background-color: #ffffff;
    z-index: 1000;
    border-bottom: 1px solid black;
    height: 50px;
}
.header-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.header-button {
    padding: 10px 15px;
    cursor: pointer;
    border: none;
    height: 100%;
    font-size: 16px;
    border-radius: 5px;
}
#add {
    background: #28a745;
    color: white;
}
#add:hover {
    background: #218838;
}
#logout {
    background: #007bff;
    color: white;
}
#logout:hover {
    background: #0056b3;
}
.public-homepage {
    text-align: center;
    margin: 20px;
    padding: 20px;
}

.public-homepage h2 {
    color: #333;
    font-size: 24px;
}

.public-homepage p {
    font-size: 18px;
    color: #666;
}

.features {
    margin: 20px 0;
}

.features h3 {
    font-size: 22px;
}

.features ul {
    list-style-type: none;
    padding: 0;
}

.features li {
    font-size: 18px;
    color: #444;
    margin: 5px 0;
}

/* Container styling for the form */
.register-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 20px;
    width: 330px; /* Consistent width with the sign-in form */
    margin: 20px auto; /* Center the form horizontally */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow */
    border-radius: 10px; /* Rounded corners */
    background-color: #ffffff; /* White background */
}

/* Input fields styling */
.register-form .form-input {
    width: 80%; /* Similar width to the sign-in form */
    padding: 12px;
    margin: 10px 0; /* Margin between inputs */
    border: 1px solid #ddd; /* Light border */
    border-radius: 5px; /* Rounded corners */
    font-size: 16px;
    transition: border 0.2s ease-in-out;
}

/* Input fields focus state */
.register-form .form-input:focus {
    border: 1px solid #007bff; /* Blue border on focus */
    outline: none; /* Remove default outline */
}

/* Button styling */
.register-form .btn {
    width: 60%;
    padding: 12px;
    margin-top: 20px;
    background: #28a745; /* Green background */
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

/* Button hover effect */
.register-form .btn:hover {
    background: #218838; /* Darker green on hover */
}

.signin {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 20px;
    margin-top: 40px;
    width: 350px; /* Set a fixed width */
    margin: 40px auto; /* Center horizontally */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    border-radius: 10px; /* Rounded corners */
    background-color: #f9f9f9; /* Light background */
}

.signin form {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
}

.signin form h1 {
    align-self: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
}

.signin form input {
    width: 80%; /* Increase width */
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    transition: border 0.2s ease-in-out;
}

.signin form input:focus {
    border: 1px solid #007bff; /* Blue border on focus */
    outline: none; /* Remove default outline */
}

.signin form button {
    width: 60%; /* Wider button */
    padding: 10px;
    margin-top: 20px;
    background: #28a745; /* Green background */
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.signin form button:hover {
    background: #218838; /* Darker green on hover */
}

.register {
    width: 60%; /* Match the login button */
    padding: 10px;
    background: #007bff; /* Blue background */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background 0.3s ease-in-out;
}

.register:hover {
    background: #0056b3; /* Darker blue on hover */
}
